$(document).on('turbolinks:load', function () {
	
	$('.mark_shipped').on("ajax:success", function(e, data, status, xhr){
		$(this).parent().find( "span.badge-warning" ).removeClass('badge-warning')
		.addClass('badge-success').html('Shipped');
		$(this).hide();
  	}).on("ajax:error", function(e, data, status, xhr) {
  		
	  }).on("ajax:beforeSend", function(e, data, status, xhr){

	  })

})	
