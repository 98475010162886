// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
Rails.start()
import Turbolinks from "turbolinks"
Turbolinks.start()
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import "channels"

import "jquery"
import "bootstrap"
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/css/all.css";
require("./external_libs/cocoon")
//trix editor
import 'trix/dist/trix.css';
import "@rails/actiontext"

import * as Trix from 'trix';
Trix.Attachment.previewablePattern = /^image(\/(gif|png|jpe?g|webp)|$)/

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./enabled/', true, /\.js$/));
requireAll(require.context('./dashboard/', true, /\.js$/));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const videos = require.context('../videos', true)
// const video = require.context('../video', true)
// const imagePath = (name) => images(name, true)

var jsErrorHasOccurred = false;

window.onerror = function () {
  jsErrorHasOccurred = true;
}

document.addEventListener("turbolinks:before-visit", function (event) {
  if (jsErrorHasOccurred == true) {
    var url = event.data.url;

    event.preventDefault(); // Cancel the turbolinks request
    window.location.href = url; // Do a regular page visit to clear the JS console
  }
});

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';

$(document).on('turbolinks:load', () => {
  flatpickr(".date-picker", { enableTime: true, dateFormat: "Y-m-d H:i", time_24hr: true });
})
