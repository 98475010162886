/* Made with love by @fitri
 This is a component of my ReactJS project
 https://codepen.io/fitri/full/oWovYj/ */

function enableDragSort(listClass) {
  const sortableLists = document.getElementsByClassName(listClass);
  Array.from(sortableLists, (list) => {enableDragList(list)});
}

function enableDragList(list) {
  Array.from(list.children, (item) => {enableDragItem(item)});
}

function enableDragItem(item) {
  item.setAttribute('draggable', true)
  item.ondrag = handleDrag;
  item.ondragend = handleDrop;
  item.ondragover = e => e.preventDefault();
  // item.ondragenter = e => e.preventDefault();
}

function handleDrag(item) {
  const selectedItem = item.target,
        list = selectedItem.parentNode,
        x = event.clientX,
        y = event.clientY;
  
  selectedItem.classList.add('drag-sort-active');
  let swapItem = document.elementFromPoint(x, y) === null ? selectedItem : document.elementFromPoint(x, y);
  
  if (list === swapItem.parentNode) {
    swapItem = swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling;
    list.insertBefore(selectedItem, swapItem);
  }
}

function handleDrop(event) {
  event.preventDefault();
  event.target.classList.remove('drag-sort-active');
  const items = Array.from(event.target.parentNode.children, (item) => { return item.getAttribute('data-dd-order') })
  document.getElementById('product_images_order').value = items
}

$(document).on('turbolinks:load', () => {
  enableDragSort('drag-drop-sort')
});
