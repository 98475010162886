import "chartkick/chart.js"
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/ranges';

$(document).on('turbolinks:load', function () {
  if(document.getElementById('litepicker') !== null){
    const picker = new Litepicker({
      element: document.getElementById('litepicker'),
      plugins: ['ranges'],
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      setup: (picker) => {
        picker.on('before:render', () => {
          let date = picker.getDate();
          if(!date) {
            picker.setStartDate(new Date());
            picker.setEndDate(new Date());
          }
        });
      }
    });
  }
  
  var validParams = ['host', 'path', 'tpl', 'font_family','font_color', 'bg_color', 'btn_bg_color', 'btn_color', 'home', 'cart', 'section', 'product', 'brand']

  var pickerCssAddon = '.cp-color-picker {background-color:#fff;color:#333;border:1px solid rgba(0, 0, 0, 0.15)}' +
  '.cp-disp {padding:3px; margin-bottom:6px; font-size:15px; line-height:20px}' +
  '.cp-xy-slider {width:200px; height:200px;}' +
  '.cp-xy-cursor {width:16px; height:16px; border-width:2px; margin:-8px}' +
  '.cp-z-slider {height:200px; width:40px;}' +
  '.cp-z-cursor {border-width:8px; margin-top:-8px;}' +
  '.cp-alpha {height:40px;}' +
  '.cp-alpha-cursor {border-width:8px; margin-left:-8px;}'

  var iframe = $('iframe.preview')
  var styleTag = $("<style>").prop("type", "text/css")
  var bg_color_picker = $('#bg_picker');
  var btns_color_picker = $('#btns_picker')

  // site - bg color & font color
  var bg_color_input = bg_color_picker.children('input[name*="bg_color"]');
  var font_color_input = bg_color_picker.children('input[name*="font_color"]');

  // buttons - bg color & font color
  var btn_bg_color_input = btns_color_picker.children('input[name*="btn_bg_color"]');
  var btn_color_input = btns_color_picker.children('input[name*="btn_color"]');

  var iframe_bgcolor_styles, iframe_btnscolor_styles

  // set color-marker on JS initialize
  bg_color_picker.val(bg_color_input.val())
  btns_color_picker.val(btn_bg_color_input.val())
  bg_color_picker.children('.cmarker').css({ backgroundColor: bg_color_input.val() })
  btns_color_picker.children('.cmarker').css({ backgroundColor: btn_bg_color_input.val() })

  iframe.on('load', function (){

    var iframe_head = iframe.contents().find("head");
    iframe_bgcolor_styles = styleTag.appendTo(iframe_head)
    iframe_btnscolor_styles = styleTag.clone().appendTo(iframe_head)

    // apply color every iframe load
    if(window.colorPicker){
      var bg_picker = bg_color_input.val();
      var btns_picker = btn_bg_color_input.val();
      if (bg_picker){
        bg_color = new Colors().setColor(bg_picker,'hex',1)
        var fontColor = bg_color.RGBLuminance > 0.22 ? '#000000' : '#ffffff'
        applyIframeBgColor("#" + bg_color.HEX , fontColor );
      }

      if(btns_picker){
        btns_color = new Colors().setColor(btns_picker,'hex',1)
        var fontColor = btns_color.RGBLuminance > 0.22 ? '#000000' : '#ffffff'
        applyIframeBtnsColor( "#" + btns_color.HEX , fontColor );
      }
    }
  })

  /*
  *   Font Menu Dropdown
  */
  var selectableDropdown = $('.dropdown-menu.font-menu')
  // resolve selected item
  var selectableDropdownInput = selectableDropdown.children('input')
  selectableDropdown.children('button').each (function(){
    $(this).removeClass('selected')
    if($(this).attr('data-font') == selectableDropdownInput.val())
      $(this).addClass('selected')
  })

  $('.dropdown-menu.font-menu button').on("click", function() {
    var button = $(this)
    if(button.attr('data-font') != null){
      selectableDropdown.children('button').removeClass('selected');
      button.addClass('selected');
      reloadIframeWithParam({font_family: button.attr('data-font')})
      button.parent().children('input').val(button.attr('data-font'))
    }
  });

  /*
  *   Layout settings
  */
  // brand - radio btn change event
  $('#page_layout select[name="site[theme_attributes][brand]"]').on( "change", function() {
    reloadIframeWithParam({brand: $(this).val()})
  });

  // home view - radio btn change event
  $('#page_layout select[name="site[theme_attributes][home]"]').on( "change", function() {
    reloadIframeWithParam({home: $(this).val(), path: '/'})
  });

  // section view - radio btn change event
  $('#page_layout select[name="site[theme_attributes][section]"]').on( "change", function() {
    reloadIframeWithParam({section: $(this).val(), path: '/shop'})
  });

  // product view - radio btn change event
  $('#page_layout select[name="site[theme_attributes][product]"]').on( "change", function() {
    reloadIframeWithParam({product: $(this).val(), path: '/shop/product/first'})
  });

  // cart view - radio btn change event
  $('#page_layout select[name="site[theme_attributes][cart]"]').on( "change", function() {
    reloadIframeWithParam({cart: $(this).val(), path: '/shop/ecarts'})
  });

  //
  //  HELPERS
  //
  function applyIframeBgColor(bgColor, color){
    if(!iframe_bgcolor_styles) return null;
    iframe_bgcolor_styles.html(
      "body,hr,.about-quote-inner {background-color: " + bgColor + "; color: " + color + ";}"+
      "a,a:hover,a:focus { color:" + color + ";}"
    )
  }

  function applyIframeBtnsColor(btnBgColor, color){
    if(!iframe_btnscolor_styles) return null;
    iframe_btnscolor_styles.html(
        " .btn,.btn.active:active, .btn.active:hover, "
      + ".btn.active:focus, .btn:hover, .btn:focus, .btn:active, "
      + ".btn:active:hover, .btn:active:focus { background-color: " + btnBgColor + "; color:" + color + ";} "
    )
  }

  function reloadIframeWithParam(params){
    var current_url = iframe.get(0).contentWindow.location.href
    var path = getParameterByName('path', current_url)
    var params_object = {}
    $.each( validParams, function( i, param ) {
      var found_param = getParameterByName(param, current_url)
      if(found_param){
        params_object[param] = found_param;
      }
    });
    $.extend(params_object, params);
    iframe.attr('src','/preview/?'+ $.param(params_object))
  }

  $(".edit_site").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
    if (xhr.getResponseHeader('Location') != null) {
      location.href = xhr.getResponseHeader('Location')
      return;
    }
    $(this).clear_form_errors();
    flashModal('Notice', data.notice);
  }).on("ajax:error", (event) => {
    const [data, status, xhr] = event.detail
    data = $.parseJSON(data.responseText);
    $(this).render_form_errors('site', data);
  }).on("ajax:beforeSend", (event) => {
    const [data, status, xhr] = event.detail
  })

  //
  //  SITE ABOUT UPDATE
  //
  $(".edit_site_about").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
    if(xhr.getResponseHeader('Location') != null) {
      location.href = xhr.getResponseHeader('Location')
      return;
    }
    $(this).clear_form_errors();
    flashModal('Notice',data.notice);
  }).on("ajax:error", (event) => {
    const [data, status, xhr] = event.detail
    data = $.parseJSON(data.responseText);
    $(this).render_form_errors('site_about', data);
  }).on("ajax:beforeSend", (event) => {
    const [data, status, xhr] = event.detail
  })

});

function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
