import { debounce } from 'throttle-debounce';

$(document).on('turbolinks:load', () => {

  // SUBMIT SELECTION FORM
  $("#process_selected_products").on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail
		$(this).find("input[type=submit]").prop("disabled", false);
		if(xhr.getResponseHeader('Location') != null) {
			location.href = xhr.getResponseHeader('Location')
		}
  }).on("ajax:error", (event) => {
      const [data, status, xhr] = event.detail
  }).on("ajax:beforeSend", (event) => {

	})

  //
  // RADIO BUTTON
  //
  $(".product-status").change((event) => {
    location.href = $(event.target).data("url")
  });

  //
  // SELECT SECTION TAG
  //
  $("#section_filter").change((event) => {
    let selectedValue = $(event.target).val()
    location.href = $(event.target).data("url") + "?section=" + selectedValue
  });

  //
  //  dashboard select items
  //
  var selectedCount = $("#selected_count")
  var selectedMax = $("#selected_max").html()
  var selectItems = $('#products-cards')
  // refresh selected items counter
  function selectedShopsCount(){
    var counted = $('#products-cards input[type=checkbox]:checked').length
    selectItems.find('.alert').remove()
    if(counted > selectedMax){
      warningText = "You have selected " + counted + " items and pricing plan allows only " + selectedMax + ". But dont't worry, if some item will expire or will be sold out we automaticly publish another selected listing instead."
      $('<div class="alert alert-warning" role="alert"/>').html(warningText).prependTo(selectItems)
    }
    if (counted > 0){

    }
    selectedCount.html(counted)
  }
  // on JS init count selected items
  selectedShopsCount()
  // on JS init count section children and determine
  // state of section checkboxes
  $('input.shop-section').each(function(){
      var childrenCheckboxes = $(this).closest('.panel').find('.panel-collapse input[type=checkbox]')
      checkChildrenCheckboxes($(this), childrenCheckboxes)
  })

  // options checkbox + toggle
  $('#products-options [id*="options"]').on('click', function() {
    var checkboxes = $('#products-cards input[type=checkbox]')
    option = $(this).prop('id');
    if(option == 'options_all'){
      // select all
      checkboxes.prop('checked', this.checked);
    } else if(option == 'options_toggle'){
      // toggle
      checkboxes.each(function(){
        $(this).prop('checked', !$(this).prop('checked'));
      })
    }
    selectedShopsCount()
  })

  $("#products-cards input[type=checkbox]").on('change', (event) => {
    var optionsAllCheckbox = $("#options_all")
    checked = $("#products-cards input[type=checkbox]").map(function () {
      return $(this).prop('checked')
    }).get()

    if (checkIfAllTrue(checked)) {
      optionsAllCheckbox.prop('indeterminate', false)
      optionsAllCheckbox.prop('checked', true)
    } else if (checkIfAllFalse(checked)) {
      optionsAllCheckbox.prop('indeterminate', false)
      optionsAllCheckbox.prop('checked', false)
    } else {
      optionsAllCheckbox.prop('indeterminate', true)
    }
    selectedShopsCount()
  })

  function checkChildrenCheckboxes(sectionCheckbox, childrenCheckboxes){
    // check section checkbox depending of children checkboxes
    checked = childrenCheckboxes.map(function(){
      return $(this).prop('checked')
    }).get()

    if(checkIfAllTrue(checked)){
      sectionCheckbox.prop('indeterminate', false)
      sectionCheckbox.prop('checked', true)
    } else if (checkIfAllFalse(checked)){
      sectionCheckbox.prop('indeterminate', false)
      sectionCheckbox.prop('checked', false)
    } else {
      sectionCheckbox.prop('indeterminate', true)
    }
  }

  function checkIfAllTrue(array){
    for(var i = 0; i < array.length; i++){
        if(array[i] !== true)
            return false;
    }
    return true;
  }

  function checkIfAllFalse(array){
    for(var i = 0; i < array.length; i++){
        if(array[i] !== false)
            return false;
    }
    return true;
  }

  function handleOnTitleType(e){
    $('#product_name_count').html(e.target.value.length)
    $.ajax({
      type: "POST", 
      url: "/dashboard/products/search",
      data: {q: e.target.value},
      success: function(response){

      },
      error: function(response){
        $('#search-results').html('')
      }
    })
  }

  $('#product_name').on('input', debounce(300, handleOnTitleType));
})
