import * as Trix from 'trix';
Trix.Attachment.previewablePattern = /^image(\/(gif|png|jpe?g|webp)|$)/

$(document).on('turbolinks:load', ()=> {
  const editorElement = document.querySelector("trix-editor")?.editor;

  $('#images-library').on('click', 'img', (e) => {
    const blob = $(e.target).attr('data-blob');
    const sgid = $(e.target).attr('data-sgid');
    const fileUrl = $(e.target).attr('data-file-url');

    const attachment = new Trix.Attachment({ 
      contentType: blob.content_type,
      filename: blob.filename,
      filesize: blob.byte_size,
      sgid: sgid,
      url: fileUrl,
      previewable: true
    })
    editorElement.insertAttachment(attachment)
  })
})


